import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CarouselProvider as Carousel,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Icon1, Icon2, Icon3 } from 'assets/LandingPage/Features';
import phone from 'assets/LandingPage/Header/phone.png';
import messengerLogo from 'assets/LandingPage/Header/messenger.png';
import iMessageLogo from 'assets/LandingPage/Header/iMessage.png';
import slackLogo from 'assets/LandingPage/Header/slack.png';
import whatsappLogo from 'assets/LandingPage/Header/whatsapp.png';
import targetSelected from 'assets/LandingPage/PopularBooks/target-icon.png';
import target from 'assets/LandingPage/PopularBooks/target-icon-default.png';
import phoneHalf from 'assets/LandingPage/cta/phone-half.png';

import { ReadingKindle, ReadingPhone } from 'assets/LandingPage/Highlights';

import styles from './landing.module.scss';

const LandingPage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(3);

  useEffect(() => {
    axios
      .get('api/books/categories/top')
      .then(response => setCategories(response.data))
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className={styles.LandingPage}>
      <header>
        <nav>
          <div className={styles.logo}>
            <Link to="/">
              <p>Chat</p>
              <p>wise</p>
            </Link>
          </div>
          <div className={styles.account}>
            <Link to="/">My Account</Link>
            <FontAwesomeIcon icon="search" />
            <FontAwesomeIcon icon="bars" />
          </div>
          <div className={styles.mobileMenu}>
            <FontAwesomeIcon icon="bars" />
          </div>
        </nav>
        <div className={styles.headerContent}>
          <div className={styles.headerTextContent}>
            <h2 className={styles.headerText}>Get Wise.Read More Books.</h2>
            <h2 className={styles.headerTextMobile}>Get Wise.</h2>
            <h2 className={styles.headerTextMobile}>Read More Books.</h2>
            <p className={styles.headerSubText}>
              Chatwise summarizes 2000+ popular non-fiction books into chat
              messages with key insights. Each book is summarized into a 10-15
              minute read.
            </p>
            <button
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
                )
              }
            >
              <img src={messengerLogo} alt="Facebook Messenger Logo" />
              Get Started
            </button>
            <div className={styles.comingSoon}>
              <img src={iMessageLogo} alt="iMessage logo" />
              <img src={slackLogo} alt="Slack logo" />
              <img src={whatsappLogo} alt="WhatsApp logo" />
              <p>Coming soon to your favorite messaging platform</p>
            </div>
          </div>
          <img
            className={styles.phone}
            src={phone}
            alt="Phone showing Chatwise app"
          />
        </div>
      </header>

      <section className={styles.featureSection}>
        <div className={styles.feature}>
          <img className={styles.featureIcon} src={Icon1} alt="feature-icon" />
          <p className={styles.featureMainText}>
            Summarized in 10 minutes or less.
          </p>
          <p className={styles.featureSubText}>
            Chatwise summarizes 2000+ popular non-fiction books into chat
            messages with key insights. Each book is summarized into a 10-15
            minute read.
          </p>
        </div>

        <div className={styles.feature}>
          <img className={styles.featureIcon} src={Icon2} alt="feature-icon" />
          <p className={styles.featureMainText}>
            Summarized in 10 minutes or less.
          </p>
          <p className={styles.featureSubText}>
            Chatwise summarizes 2000+ popular non-fiction books into chat
            messages with key insights. Each book is summarized into a 10-15
            minute read.
          </p>
        </div>

        <div className={styles.feature}>
          <img className={styles.featureIcon} src={Icon3} alt="feature-icon" />
          <p className={styles.featureMainText}>
            Summarized in 10 minutes or less.
          </p>
          <p className={styles.featureSubText}>
            Chatwise summarizes 2000+ popular non-fiction books into chat
            messages with key insights. Each book is summarized into a 10-15
            minute read.
          </p>
        </div>
      </section>

      <section className={styles.popularSection}>
        <div className={styles.selectedCategory}>
          <div className={styles.topRow}>
            <h1 className={styles.mainText}>
              Most Popular in{' '}
              {categories.length && categories[selectedCategory].category}
            </h1>
            <button
              className={styles.viewAll}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
                )
              }
            >
              View all
            </button>
          </div>
          <div className={styles.bottomRow}>
            {categories.length &&
              categories[selectedCategory].books.map(book => {
                while (book.synopsis.length > 350) {
                  const split = book.synopsis.split('. ');
                  book.synopsis = split.slice(0, split.length - 1).join('. ');
                  book.synopsis += '.';
                }
                return (
                  <div key={book.id} className={styles.bookCard}>
                    <div className={styles.imageContainer}>
                      <img src={book.image_url} alt="book-cover" />
                    </div>
                    <div className={styles.cardBase}>
                      <div className={styles.cardContent}>
                        <h2 className={styles.cardMainText}>{book.title}</h2>
                        <h2 className={styles.cardSubText}>By {book.author}</h2>
                        <p className={styles.cardSubText}>{book.synopsis}</p>
                        <button
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
                            )
                          }
                        >
                          <b>Read Now</b>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className={styles.bottomRowMobile}>
            <Carousel
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={2}
              visibleSlides={1}
              dragEnabled={false}
            >
              <Slider className={styles.slider}>
                {categories.length &&
                  categories[selectedCategory].books.map(book => {
                    while (book.synopsis.length > 325) {
                      const split = book.synopsis.split('. ');
                      book.synopsis = split
                        .slice(0, split.length - 1)
                        .join('. ');
                      book.synopsis += '.';
                    }
                    return (
                      <Slide
                        key={book.id}
                        className={styles.bookCard}
                        style={{
                          paddingBottom: 0
                        }}
                        innerClassName={styles.innerSlide}
                      >
                        <div className={styles.imageContainer}>
                          <img src={book.image_url} alt="book-cover" />
                        </div>
                        <div className={styles.cardBase}>
                          <div className={styles.cardContent}>
                            <h2 className={styles.cardMainText}>
                              {book.title}
                            </h2>
                            <h2 className={styles.cardSubText}>
                              By {book.author}
                            </h2>
                            <p className={styles.cardSubText}>
                              {book.synopsis}
                            </p>
                            <button
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
                                )
                              }
                            >
                              <b>Read Now</b>
                            </button>
                          </div>
                        </div>
                      </Slide>
                    );
                  })}
              </Slider>
              <div className={styles.buttonsContainer}>
                <ButtonBack className={styles.controlButton}>
                  <FontAwesomeIcon icon="arrow-alt-circle-left" />
                </ButtonBack>
                <button
                  className={styles.viewAllMobile}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
                    )
                  }
                >
                  View all
                </button>
                <ButtonNext className={styles.controlButton}>
                  <FontAwesomeIcon icon="arrow-alt-circle-right" />
                </ButtonNext>
              </div>
            </Carousel>
          </div>
        </div>

        <div className={styles.categoryMenu}>
          <div className={styles.categoryDescription}>
            <h2 className={styles.categoryMainText}>Popular Titles in Over</h2>
            <h2 className={styles.categoryMainText}>
              {categories.length < 10
                ? `0${categories.length}`
                : categories.length}{' '}
              Categories
            </h2>
            <p className={styles.categorySubText}>
              The world's best non-fiction books summarized in 15 minute chat
              messages!
            </p>
          </div>
          {/* Category Buttons */}
          {categories && categories.length ? (
            <div className={styles.categoryOptions}>
              {categories.map((category, index) => {
                const isActive = selectedCategory === index;
                return (
                  <button
                    key={category.category}
                    className={(isActive && styles.activeButton) || null}
                    name={category.category}
                    onClick={() => setSelectedCategory(index)}
                  >
                    <img
                      src={isActive ? targetSelected : target}
                      alt="Target icon"
                    />
                    {category.category}
                  </button>
                );
              })}
            </div>
          ) : (
            <p>No categories</p>
          )}
        </div>
      </section>

      <section className={styles.highlightSection}>
        <div className={styles.highlightRow}>
          <div className={styles.highlightImageContainer}>
            <img
              className={styles.highlightImage}
              src={ReadingKindle}
              alt="highlight"
            />
          </div>
          <div className={styles.highlightContent}>
            <h1 className={styles.highlightMainText}>
              Consume More in Less Time
            </h1>
            <p className={styles.highlightSubText}>
              Chatwise summarizes 2000+ popular non-fiction books into chat
              messages with key insights. Each book is summarized into a 10-15
              minute read. Chatwise summarizes 2000+ popular non-fiction books
              into chat messages with key insights. Each book is summarized into
              a 10-15 minute read.
            </p>
            <div className={styles.redBtn}>
              <button
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
                  )
                }
              >
                <b>Read More</b>
              </button>
            </div>
          </div>
        </div>

        <div className={styles.highlightRow}>
          <div className={styles.highlightContent}>
            <h1 className={styles.highlightMainText}>
              Podcast and Magazine Summaries
            </h1>
            <p className={styles.highlightSubText}>
              Chatwise summarizes 2000+ popular non-fiction books into chat
              messages with key insights. Each book is summarized into a 10-15
              minute read. Chatwise summarizes 2000+ popular non-fiction books
              into chat messages with key insights. Each book is summarized into
              a 10-15 minute read.
            </p>
            <div className={styles.redBtn}>
              <button
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
                  )
                }
              >
                <b>Read More</b>
              </button>
            </div>
          </div>
          <div className={styles.highlightImageContainer}>
            <img
              className={styles.highlightImage}
              src={ReadingPhone}
              alt="highlight"
            />
          </div>
        </div>
      </section>

      <section className={styles.ctaSection}>
        <div className={styles.ctaContent}>
          <h1 className={styles.ctaMainText}>Chatwise Makes You Smarter</h1>
          <p className={styles.ctaSubText}>
            The world's best non-fiction books summarized in 15 minute chat
            messages.
          </p>
          <button
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_MESSENGER_URL}?ref=command=get_started`
              )
            }
          >
            Start Free Trial
          </button>
        </div>
        <div className={styles.ctaImageContainer}>
          <img src={phoneHalf} alt="Phone displaying Chatwise" />
        </div>
      </section>

      <footer>
        <div className={styles.topBar}>
          <div className={styles.column}>
            <div className={styles.logo}>
              <Link to="/">
                <p>Chat</p>
                <p>wise</p>
              </Link>
            </div>
            <p className={styles.footerSubText}>
              Chatwise summarizes 2000+ popular non-fiction books into chat
              messages with key insights. Each book is summarized into a 10-15
              minute read.
            </p>
          </div>

          <div className={styles.column}>
            <p className={styles.footerMainText}>Products</p>
            <p className={styles.footerSubText}>Features</p>
            <p className={styles.footerSubText}>Why Chatwise</p>
            <p className={styles.footerSubText}>FAQ</p>
            <p className={styles.footerSubText}>Pricing</p>
            <p className={styles.footerSubText}>Customers</p>
          </div>

          <div className={styles.column}>
            <p className={styles.footerMainText}>Links</p>
            <p className={styles.footerSubText}>About Us</p>
            <p className={styles.footerSubText}>Blog</p>
            <p className={styles.footerSubText}>Download</p>
            <p className={styles.footerSubText}>Support</p>
            <p className={styles.footerSubText}>Events</p>
          </div>

          <form className={styles.column}>
            <p className={styles.footerMainText}>Get in Touch</p>
            <p className={styles.footerSubText}>
              Enter your email and we'll send you more information.
            </p>
            <input type="text" />
            <button>
              <b>Subscribe</b>
            </button>
          </form>
        </div>

        <div className={styles.bottomBar}>
          <p className={styles.footerMainText}>Copyright 2019 Chatwise, LLC.</p>
          <div className={styles.shareButtons}>
            <FontAwesomeIcon
              icon={['fab', 'facebook-f']}
              style={{ color: '#4266B2' }}
            />
            <FontAwesomeIcon
              icon={['fab', 'instagram']}
              style={{ color: '#EE2D00' }}
            />
            <FontAwesomeIcon
              icon={['fab', 'twitter']}
              style={{ color: '#6FAEDC' }}
            />
            <FontAwesomeIcon
              icon={['fab', 'youtube']}
              style={{ color: '#FE3000' }}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
