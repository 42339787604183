// Font Awesome Library -- allows for use throughout the app
import { library } from '@fortawesome/fontawesome-svg-core';

import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faSearch,
  faBars,
  faArrowAltCircleLeft,
  faArrowAltCircleRight
} from '@fortawesome/free-solid-svg-icons';

library.add(fab, faSearch, faBars, faArrowAltCircleLeft, faArrowAltCircleRight);

export default library;
