import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.js';
// import FeedbackForm from './views/feedback/components/Form.js';

import Providers from 'Providers/';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import 'interceptors/';
import './index.scss';
import './SCSS/index.scss';
import './faLibrary.js';

ReactDOM.render(
  <Providers>
    <Router>
      <Route path="/">
        {/* <FeedbackForm /> */}
        <App />
      </Route>
    </Router>
  </Providers>,
  document.getElementById('root')
);
