export const initialState = {
  isActive: ''
};

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
