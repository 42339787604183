import React from 'react';
import { Route } from 'react-router-dom';

// import NavBar from './navigation/NavDrawer';
import LandingPage from './views/Landing/LandingPage';
// import BillingRoutes from './views/billing/Routes/';
// import BookRoutes from 'views/books/Routes';

import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.App}>
      <Route exact path="/">
        <LandingPage />
      </Route>
    </div>
  );
}

export default App;
